import { Typography } from "@suraasa/placebo-ui"
import api from "api"
import DarkOverlayLoading from "components/shared/DarkOverlayLoading"
import { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { getAuthInfo, getCurrentPlatform, isValidPlatform } from "utils/auth"
import { Platforms, Product } from "utils/constants"
import SchoolLogin from "views/auth/school/Login"

import HybridLogin from "./HybridLogin"
import PartnerLogin from "./partner/Login"
import SVPLogin from "./SVPLogin"

const isSuraasaURL = (url: URL) => {
  const [TLD, domain] = url.hostname.split(".").reverse()
  return domain === "suraasa" && TLD === "com"
}

const allowOrigin = (origin: string | null) => {
  if (!origin) return false

  const url = new URL(origin)
  if (import.meta.env.VITE_ALLOW_LOCALHOST_SSO_MESSAGING === "true") {
    return isSuraasaURL(url) || url.hostname === "localhost"
  }

  return isSuraasaURL(url)
}

const PopupSSO = () => {
  const { platform } = getCurrentPlatform()
  const [searchParams] = useSearchParams()
  const auth = getAuthInfo(platform)

  const origin = searchParams.get("origin")

  const [error, setError] = useState("")

  const postCode = useCallback(
    (code: string) => {
      if (!allowOrigin(origin)) {
        console.error("Invalid origin")
        return
      }
      if (window.opener) {
        window.opener.postMessage({ code }, origin)
        window.close()
        return
      }
      console.warn("> No opener found. Exiting")
    },
    [origin]
  )

  const generateAuthCode = useCallback(
    async (platformKey: Platforms) => {
      const res = await api.users.generateAuthCode({
        data: { platform: platformKey },
      })
      if (res.isSuccessful) {
        postCode(res.data.code)
      } else if (res.errors.message) {
        console.error(res.errors.message)
      }
    },
    [postCode]
  )

  useEffect(() => {
    const handleLogin = () => {
      if (!(platform && isValidPlatform(platform))) {
        setError("Invalid URL")
        console.error("Invalid platform")
        return
      }
      if (!allowOrigin(origin)) {
        setError("Invalid URL")
        console.error("Invalid origin")
        return
      }

      if (auth) {
        generateAuthCode(platform)
      }
    }

    handleLogin()
  }, [platform, generateAuthCode, auth, origin])

  if (error)
    return (
      <DarkOverlayLoading
        enabled={Boolean(auth && auth.platform === platform)}
        message={<Typography variant="largeTitle">{error}</Typography>}
        size="lg"
      />
    )

  return (
    <DarkOverlayLoading
      enabled={Boolean(auth && auth.platform === platform)}
      message={<Typography variant="largeTitle">Authenticating</Typography>}
      size="lg"
    >
      {(() => {
        switch (platform) {
          case Platforms.olympiadPartner:
            return <PartnerLogin onSuccess={postCode} />

          case Platforms.school:
            return <SchoolLogin />

          case Platforms.svp:
            return <SVPLogin />

          default:
            return <HybridLogin onAuthCodeGen={postCode} />
        }
      })()}

      <SVPLogin />
      <SchoolLogin />
    </DarkOverlayLoading>
  )
}

export default PopupSSO
