import ITULogo from "assets/logos/itu-mini-crimson.png"
import MiniLogo from "assets/logos/suraasa-colored-small.svg"
import FullLogo from "assets/logos/suraasa-logo-new.svg"
import clsx from "clsx"
import React from "react"
import { useSearchParams } from "react-router-dom"

type Props = {
  className?: string
  href?: string
  /**
   * Replaces Suraasa full logo with Suraasa mini logo
   * and appends slotStart children to the right of the updated logo
   */
  slotStart?: React.ReactElement | null
}

const NavLogo = ({ className, href = "#", slotStart }: Props) => {
  const [searchParams] = useSearchParams()

  const isFromITU = searchParams.get("login_source") === "itu"

  const logos = {
    large: [FullLogo, isFromITU ? ITULogo : undefined].filter(Boolean),
    small: [MiniLogo, isFromITU ? ITULogo : undefined].filter(Boolean),
  }

  return (
    <div className={clsx("flex items-center gap-1", className)}>
      {logos.small.map((x, i) => {
        return (
          <div
            key={i}
            className={clsx("md:hidden", {
              "border-l-2": i >= 1,
              "pl-2": i >= 1,
            })}
          >
            <a href={href}>
              <img
                src={x}
                alt=""
                height="40"
                width="50"
                className="max-h-[40px] object-contain"
              />
            </a>
          </div>
        )
      })}

      {logos.large.map((x, i) => {
        return (
          <div
            key={i}
            className={clsx("hidden md:block", {
              "border-l-2": i >= 1,
              "pl-2": i >= 1,
            })}
          >
            <a href={href}>
              <img
                src={x}
                alt=""
                height="40"
                width="100%"
                className="max-h-[40px] object-contain"
              />
            </a>
          </div>
        )
      })}

      {slotStart && slotStart}
    </div>
  )
}

export default NavLogo
