import { AuthData } from "api/resources/users/types"
import { webengage } from "utils/webengage"

type Service = "MoEngage" | "WebEngage"

const allServices: Service[] = ["MoEngage", "WebEngage"]

export const trackingService = {
  initUser: (
    authInfo: {
      user: Pick<AuthData["user"], "firstName" | "lastName" | "uuid" | "email">
    },
    services = allServices
  ) => {
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.user.login(authInfo.user.uuid)
        webengage.user.setAttribute("we_email", authInfo.user.email)
        webengage.user.setAttribute("we_first_name", authInfo.user.firstName)
        webengage.user.setAttribute(
          "we_last_name",
          authInfo.user.lastName || ""
        )
      }
    } catch (e) {
      console.warn("Error initialising user on WebEngage", e)
    }
  },

  trackEvent: (eventName: string, data?: any, services = allServices) => {
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.track(eventName, data)
      }
    } catch (e) {
      console.warn(`Error tracking event on WebEngage: ${eventName}`, data)
      console.warn(e)
    }
  },

  getSignupSource: (source?: string) => {
    const searchParams = new URLSearchParams(window.location.search)

    const utmParams = trackingService.getUTMParams()

    const value =
      source ||
      searchParams.get("signup_source") ||
      utmParams.utm_source ||
      document.referrer ||
      "direct"

    return value
  },

  getUTMParams: () => {
    const searchParams = new URLSearchParams(window.location.search)

    const utmParams = {
      utm_source: searchParams.get("utm_source"),
      utm_medium: searchParams.get("utm_medium"),
      utm_campaign: searchParams.get("utm_campaign"),
      utm_term: searchParams.get("utm_term"),
      utm_content: searchParams.get("utm_content"),
    }

    return utmParams
  },
}
