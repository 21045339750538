import { User } from "api/resources/users/types"
import { SocialAuthProvider } from "components/auth/SocialAuth"
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore"
import { buildUserName } from "utils/helpers"
import { trackingService } from "utils/tracking"

import { firebaseApp } from "./init"

export const db = getFirestore(firebaseApp)

enum CollectionName {
  signupTracking = "signupTracking",
  featureToggles = "featureToggles",
}

export const trackSignupSourceOnFirebase = async (user: User) => {
  const docRef = doc(db, CollectionName.signupTracking, user.email)

  const data = {
    email: user.email,
    name: buildUserName({
      firstName: user.firstName,
      lastName: user.lastName,
    }),
    uuid: user.uuid,
    source: trackingService.getSignupSource(),
  }
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    console.info("User already exists, returning", data)
    return
  }
  console.info("Tracking signup source on Firebase", data)
  setDoc(docRef, { data })
  return null
}

// const listSignupSource = async () => {
//   const signupTrackingCollection = collection(db, CollectionName.signupTracking)
//   const docs = await getDocs(signupTrackingCollection)

//   const source = "al-durrah-international-school-sharjah"

//   const list: any = []
//   docs.forEach(doc => {
//     const data = doc.data()
//     if (data.data.source === source) {
//       list.push(data.data)
//     }
//   })

//   console.log(list)
//   return list
// }

// listSignupSource()

export const getFeatureToggleConfig = async () => {
  const featureToggleCollection = collection(db, CollectionName.featureToggles)
  const docs = await getDocs(featureToggleCollection)

  const data: any = {}
  docs.forEach(doc => {
    data[doc.id] = doc.data()
  })
  return data as FeatureToggleConfig
}

export const SOCIAL_AUTH_FIREBASE_KEY = "socialAccountAuthentication"

type FeatureToggleConfig = {
  [SOCIAL_AUTH_FIREBASE_KEY]: {
    enabled: {
      web: boolean
      app: boolean
    }
    providers: SocialAuthProvider[]
  }
}
