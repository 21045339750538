import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getKeplerURL = getServiceURL(ServicePrefix.kepler)

export const urls = validateUrls({
  help: {
    list: () => getKeplerURL("/v1/help/categories/"),
    create: () => getKeplerURL("/v1/help/"),
  },
})
