import Glide from "@glidejs/glide"
import Slide1 from "assets/auth/slide1.png"
import Slide2 from "assets/auth/slide2.png"
import Slide3 from "assets/auth/slide3.png"
import clsx from "clsx"
import Navbar from "components/shared/Navbar"
import React, { PropsWithChildren, useEffect } from "react"
import { createUseStyles } from "react-jss"
import { Platforms } from "utils/constants"

import SlideLayout from "./SlideLayout"

const useStyles = createUseStyles(theme => ({
  root: {
    height: "calc(100vh - 62px)",
    background: theme.colors.common.white[500],
  },
  sliderContainer: {
    width: "100%",
    overflow: "hidden",
    background: theme.colors.surface[50],
  },
  slide: {
    width: "100%",
  },
  controlsContainer: {
    position: "absolute",
    left: "50%",
    bottom: "2.5rem",
    transform: "translateX(-50%)",
  },
  sliderControl: {
    width: "35px",
    height: "2px",
    background: theme.colors.onSurface[300],

    "&[class*=active]": {
      background: theme.colors.interactive[500],
    },
  },

  formContainer: {
    overflow: "auto",
    height: "100%",
  },
}))

const SchoolAuthLayout = ({ children }: PropsWithChildren<unknown>) => {
  const classes = useStyles()

  useEffect(() => {
    const glide = new Glide("#slider", {
      type: "slider",
      gap: 0,
      perView: 1,
      autoplay: 7000,
      hoverpause: false,
    }).mount()

    return () => {
      glide.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Navbar gutterBottom={false} platform={Platforms.school} />
      <div className={clsx("md:grid md:grid-cols-5", classes.root)}>
        <div
          className={clsx(
            "col-span-2 hidden md:flex flex-col justify-center",
            classes.sliderContainer
          )}
        >
          <div id="slider" style={{ position: "relative", minHeight: "645px" }}>
            <div
              className="glide__track"
              data-glide-el="track"
              style={{ height: "100%" }}
            >
              <ul className="flex glide__slides" style={{ height: "100%" }}>
                <SlideLayout
                  description="After evaluating and comparing candidates, create and send offer letters directly via Suraasa."
                  image={Slide1}
                  title="Skilled Teachers"
                />
                <SlideLayout
                  description="After evaluating and comparing candidates, create and send offer letters directly via Suraasa."
                  image={Slide2}
                  title="Verified Profiles"
                />
                <SlideLayout
                  description="After evaluating and comparing candidates, create and send offer letters directly via Suraasa."
                  image={Slide3}
                  title="Hire Faster."
                />
              </ul>
            </div>
            <div
              className={clsx("flex gap-1.5", classes.controlsContainer)}
              data-glide-el="controls[nav]"
            >
              <button
                aria-label="slider-nav"
                className={classes.sliderControl}
                data-glide-dir="=0"
              />
              <button
                aria-label="slider-nav"
                className={classes.sliderControl}
                data-glide-dir="=1"
              />
              <button
                aria-label="slider-nav"
                className={classes.sliderControl}
                data-glide-dir="=2"
              />
            </div>
          </div>
        </div>
        <div
          className={clsx(
            classes.formContainer,
            "pt-6 sm:pt-10 md:pt-0 flex flex-col items-center md:justify-center sm:col-span-3"
          )}
        >
          <div className="px-1 sm:-mt-6 sm:px-3 ">{children}</div>
        </div>
      </div>
    </>
  )
}

export default SchoolAuthLayout
