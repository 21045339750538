import { CircularProgress } from "@suraasa/placebo-ui"
import React, { PropsWithChildren, Suspense } from "react"

const LazyLoadedRoute = ({ children }: PropsWithChildren<any>) => (
  <Suspense
    fallback={
      <div
        style={{
          minHeight: "100vh",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    }
  >
    {children}
  </Suspense>
)

export default LazyLoadedRoute
