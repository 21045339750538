import api from "api"
import { useCallback, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import {
  getAuthInfo,
  getCurrentPlatform,
  isValidOrigin,
  isValidPlatform,
} from "utils/auth"
import { Platforms } from "utils/constants"

const IframeSSO = () => {
  const [searchParams] = useSearchParams()

  const { platform } = getCurrentPlatform()

  const origin = searchParams.get("origin")

  const generateAuthCode = useCallback(
    async (platformKey: Platforms) => {
      if (!(origin && isValidOrigin(origin))) {
        console.error("Invalid origin")
        return
      }

      const res = await api.users.generateAuthCode({
        data: { platform: platformKey },
      })
      if (res.isSuccessful) {
        const { code } = res.data
        window.parent.postMessage({ code }, origin)
      } else if (res.errors.message) console.error(res.errors.message)
    },
    [origin]
  )

  useEffect(() => {
    const handleLogin = () => {
      const auth = getAuthInfo(platform)

      if (!auth) {
        console.error("User not authenticated")
        if (origin) window.parent.postMessage({ code: null }, origin)

        return
      }
      if (!isValidPlatform(platform)) {
        console.error("Invalid platform")
        return
      }

      generateAuthCode(platform)
    }
    handleLogin()
  }, [origin, platform, generateAuthCode])

  return <h1>Authing</h1>
}

export default IframeSSO
