import type { Country, Language, Subject } from "api/resources/global/types"
import { createContext, useState } from "react"
import { Outlet } from "react-router-dom"

type Context = {
  countries: Country[]
  languages: Language[]
  subjects: Subject[]
}

export const GlobalContext = createContext<Context>({
  countries: [],
  languages: [],
  subjects: [],
})

const GlobalState = () => {
  const [countries, setCountries] = useState<Country[]>([])
  const [languages, setLanguages] = useState<Language[]>([])
  const [subjects, setSubjects] = useState<Subject[]>([])

  // TODO(future): This is not being used right now. Can be removed in the future
  // useEffect(() => {
  //   const getData = async () => {
  //     const listCountries = api.global.listCountries({ params: { page: "-1" } })
  //     const listLanguages = api.global.listLanguages({ params: { page: "-1" } })
  //     const listSubjects = api.global.listSubjects({ params: { page: "-1" } })

  //     const [countriesRes, languagesRes, subjectsRes] = await Promise.all([
  //       listCountries,
  //       listLanguages,
  //       listSubjects,
  //     ])

  //     if (countriesRes.isSuccessful) setCountries(countriesRes.data)

  //     if (languagesRes.isSuccessful) setLanguages(languagesRes.data)

  //     if (subjectsRes.isSuccessful) setSubjects(subjectsRes.data)
  //   }
  //   getData()
  // }, [])

  // TODO: Implement this if we want profile picture in navbar
  // useEffect(() => {
  // const getProfile = async () => {
  //   const res = await api.profile.retrieve({
  //     urlParams: { username: null },
  //   })
  //   if (res.isSuccessful) {
  //     setUser({
  //       firstName: res.data.user.firstName ?? "",
  //       lastName: res.data.user.lastName ?? "",
  //       photo: res.data.picture,
  //     })
  //   }
  // }
  // if (getAuthInfo()) getProfile()
  // }, [])

  return (
    <GlobalContext.Provider
      value={{
        countries,
        languages,
        subjects,
      }}
    >
      <Outlet />
    </GlobalContext.Provider>
  )
}

export default GlobalState
