import { SocialAuthProvider } from "components/auth/SocialAuth"
import {
  getFeatureToggleConfig,
  SOCIAL_AUTH_FIREBASE_KEY,
} from "services/firebase/database"

import { LocalStorage } from "./storageFactory"

const LOCAL_CACHE_KEY = "featureToggleManagerCache"

export type FeatureToggleType = {
  socialAuth: {
    isEnabled: boolean
    providers: SocialAuthProvider[]
  }
}
export const featureToggleDefaultValues: FeatureToggleType = {
  socialAuth: {
    isEnabled: false,
    providers: [],
  },
}

export const FeatureToggleManager = {
  socialAuth: featureToggleDefaultValues.socialAuth,

  updateCache() {
    const data = JSON.stringify({
      socialAuth: this.socialAuth,
    })
    LocalStorage.setItem(LOCAL_CACHE_KEY, data)
  },
  getConfig() {
    return {
      socialAuth: this.socialAuth,
    }
  },

  init() {
    try {
      const data: {
        [x: string]: any
      } = JSON.parse(LocalStorage.getItem(LOCAL_CACHE_KEY))

      Object.entries(data).forEach(([key, value]) => {
        if (["socialAuth"].includes(key)) {
          // @ts-expect-error only updating the configs we wants to save
          this[key] = value
        }
      })
      return this
    } catch (error) {
      return this
    }
  },
  async syncFromFirebase() {
    const data = await getFeatureToggleConfig()

    const socialAuthData = data[SOCIAL_AUTH_FIREBASE_KEY]
    if (socialAuthData) {
      this.socialAuth.isEnabled =
        socialAuthData.enabled.web && socialAuthData.providers.length > 0
      this.socialAuth.providers = socialAuthData.providers
    }

    this.updateCache()
    return this
  },
}
