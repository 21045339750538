import "./styles/index.css"

import * as Sentry from "@sentry/react"
import ErrorScreen from "components/ErrorScreen"
import { createRoot } from "react-dom/client"
import { getAuthInfo } from "utils/auth"
import { checkEnvVars } from "utils/helpers"

import { version } from "../package.json"
import App from "./App"

checkEnvVars([
  "VITE_API_ENDPOINT",
  "VITE_SCHOOL_PLATFORM_URL",
  "VITE_PARTNER_PLATFORM_URL",
  "VITE_SVP_PLATFORM_URL",
  "VITE_LEARN_PLATFORM_URL",
  "VITE_SURAASA_PLATFORM_URL",
  "VITE_ADMISSIONS_ADMIN_PLATFORM_URL",
])

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: version,
  })
  const authInfo = getAuthInfo()
  if (authInfo)
    Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
}

/**
 * react-jss does has issues with
 * functional styles that use media queries.
 * https://github.com/cssinjs/jss/issues/1320
 *
 * * Disabling strict mode fixes this issue
 */

const container = createRoot(document.getElementById("root") as HTMLElement)
container.render(
  <Sentry.ErrorBoundary
    fallback={
      <ErrorScreen error="An unexpected error occurred. Please try reloading the page." />
    }
  >
    <App />
  </Sentry.ErrorBoundary>
)
