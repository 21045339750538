import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
  },
  imageContainer: {
    width: "100%",
    maxHeight: "390px",

    "& > img": {
      width: "100%",
      objectFit: "contain",
    },

    [theme.breakpoints.up("xl")]: {
      maxHeight: "600px",
      "& > img": {
        width: "95%",
        objectFit: "contain",
      },
    },
  },
}))

type Props = {
  image: string
  title: string
  description: string
}

const SlideLayout = ({ description, image, title }: Props) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(
        classes.root,
        "px-8 py-11 xl:px-11 flex flex-col justify-center items-center"
      )}
    >
      <div className={clsx("mb-4 flex justify-center", classes.imageContainer)}>
        <img
          alt={title}
          src={image}
          style={{ maxWidth: "580px" }}
          width="100%"
        />
      </div>
      <div>
        <Typography className="mb-1.5" color="interactive.500" variant="title2">
          {title}
        </Typography>
        <Typography color="onSurface.500" variant="body">
          {description}
        </Typography>
      </div>
    </div>
  )
}

export default SlideLayout
