import axios from "api/axios"
import { APIResponse, Config, NoContentType } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { HelpCategory } from "./types"
import { urls } from "./urls"

export default {
  list: async (): Promise<APIResponse<HelpCategory[]>> => {
    try {
      const res = await axios.get(urls.help.list())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  create: async ({
    data,
    headers,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.help.create(), data, {
        headers,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
