import { Button, Container, Typography } from "@suraasa/placebo-ui"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.colors.onSurface[200]}`,
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.root}>
      <Container>
        <div className="flex justify-between py-4">
          <Typography>
            © {new Date().getFullYear()} Les Transformations Learning Pvt. Ltd.
          </Typography>
          <div>
            <Button
              color="secondary"
              component="a"
              href="https://www.suraasa.com/terms-of-use"
              variant="text"
            >
              Terms
            </Button>
            <span className="mx-1">🞄</span>
            <Button
              color="secondary"
              component="a"
              href="https://www.suraasa.com/privacy-policy"
              variant="text"
            >
              Privacy Policy
            </Button>
            <span className="mx-1">🞄</span>
            <Button
              color="secondary"
              component="a"
              href="https://www.suraasa.com/eula"
              variant="text"
            >
              EULA
            </Button>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
