const MEASUREMENT_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID
export interface CustomWindow extends Window {
  gtag?: (...args: any[]) => void
}
declare let window: CustomWindow

export const GA_EVENTS = {
  login: "login",
  signup: "signup",
}

// export const GA_INTENT = {}

const gtag = (...params: any) => {
  if (typeof window !== "undefined" && window.gtag) {
    if (MEASUREMENT_ID) console.info("> Logging event to GA", params)
    return window.gtag(...params)
  }
}

export const GA = {
  trackEvent(name: keyof typeof GA_EVENTS | string, payload: any) {
    gtag("event", name, payload)
  },
  setUserId(userId: string) {
    gtag("config", MEASUREMENT_ID, { user_id: userId })
  },
  init() {
    if (!MEASUREMENT_ID) return

    const gtagScript = document.createElement("script")
    gtagScript.setAttribute(
      "src",
      `https://www.googletagmanager.com/gtag/js?id=${MEASUREMENT_ID}`
    )
    gtagScript.setAttribute("async", "true")
    document.head.appendChild(gtagScript)

    const script2 = document.createElement("script")
    script2.innerHTML = `
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag("js", new Date())
    gtag("config", "${MEASUREMENT_ID}")
    `
    document.head.appendChild(script2)
  },
}
