import { Button, TextField, Theme, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { AuthData } from "api/resources/users/types"
import SocialAuth from "components/auth/SocialAuth"
import { useFeatureToggle } from "components/FeatureToggleProvider"
import LinkWithQuery from "components/shared/LinkWithQuery"
import LoadingOverlay from "components/shared/LoadingOverlay"
import Navbar from "components/shared/Navbar"
import { ArrowRight } from "iconoir-react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { createUseStyles, useTheme } from "react-jss"
import { useNavigate, useSearchParams } from "react-router-dom"
import { trackSignupSourceOnFirebase } from "services/firebase/database"
import { getCurrentPlatform, savePlatformAuthInfo } from "utils/auth"
import { Platforms } from "utils/constants"
import { GA, GA_EVENTS } from "utils/googleAnalytics"
import { getPlatformURL, mapErrors, trackUser } from "utils/helpers"
import { useGRecaptcha } from "utils/hooks/useGRecaptcha"
import { redirect } from "utils/hooks/useHybridLogin"
import { routes } from "utils/routes"
import toast from "utils/toast"
import { trackingService } from "utils/tracking"

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },

  content: {
    display: "flex",
    flexGrow: 1,
  },

  form: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: 20,
    },

    [theme.breakpoints.up("sm")]: {
      margin: "auto",
      padding: 30,
      width: 500,
      background: "white",
      borderRadius: "12px",
      boxShadow: "1px 1px 5px rgb(0 0 0 / 10%)",
    },
  },
  signUpBtn: {
    ...theme.typography.strongSmallBody,
    color: "black",
    textDecoration: "underline",
    textUnderlineOffset: "5px",
    marginInlineStart: "2px",
  },
}))

const formatName = (name: string) => {
  if (name.length > 20) {
    return `${name.substring(0, 20)}...`
  }
  return name
}

type FormData = {
  firstName: string
  lastName: string
  email: string
  password1: string
  password2: string
}

type Props = {
  hideNavBar?: boolean
  signInRoute?: string
}

const SignUp = ({ hideNavBar = false, signInRoute = routes.login }: Props) => {
  const classes = useStyles()
  const [step, setStep] = useState<"name" | "password" | "email">("name")
  const theme = useTheme<Theme>()

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const { platform, product } = getCurrentPlatform()

  const [loading, toggleLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState("")
  const [isSocialAuthLoading, setIsSocialAuthLoading] = useState(false)

  const featureToggle = useFeatureToggle()
  const socialAuthEnabled = featureToggle.socialAuth.isEnabled

  const {
    register,
    setError,
    clearErrors,
    getValues,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<FormData>()

  const handleRedirect = (code?: string) => {
    const redirectUrl = searchParams.get("redirect-url")
    if (redirectUrl) {
      const url = new URL(redirectUrl)
      if (code) url.searchParams.append("code", code)
      window.location.href = url.href
    } else {
      console.warn("> redirect-url not found. Redirecting to profile")
      redirect(product, platform)
    }
  }

  useEffect(() => {
    if (platform === Platforms.school) {
      navigate(routes.school.signUp, { replace: true })
    }
  }, [platform])

  const generateAuthCode = async (platformKey: Platforms) => {
    toggleLoading(true)
    setLoadingMessage("You will be redirected shortly")
    const res = await api.users.generateAuthCode({
      data: { platform: platformKey },
    })
    if (res.isSuccessful) {
      const { code } = res.data
      handleRedirect(code)
    } else {
      toggleLoading(false)
      setLoadingMessage("")
      if (res.errors.message) console.log(res.errors.message)
    }
  }

  const { submitWithCaptcha, triggerCheckboxCaptcha, resetCaptcha } =
    useGRecaptcha({
      action: "auth",
      checkboxContainer: "#recaptcha-checkbox-container",
    })

  const onAuthSuccess = async (data: Omit<AuthData, "platform">) => {
    savePlatformAuthInfo(platform, data)

    try {
      await trackSignupSourceOnFirebase(data.user)
    } catch (e) {
      console.error("Error tracking signup source", e)
    }

    trackUser(data)
    trackingService.initUser(data)
    trackingService.trackEvent(
      "USER_SIGNUP",
      {
        uuid: data.user.uuid,
        email: data.user.email.trim(),
        firstName: data.user.firstName.trim(),
        lastName: data.user.lastName?.trim() || null,
        platform: platform,
        source: trackingService.getSignupSource(),
        deviceType: "web",
      },
      ["WebEngage"]
    )

    GA.trackEvent(GA_EVENTS.signup, {
      source: trackingService.getSignupSource(),
    })

    if (platform) {
      await generateAuthCode(platform)
    } else {
      navigate(routes.profile)
    }
  }

  const onSubmit = handleSubmit(async formData => {
    clearErrors()
    if (step === "name") {
      setStep("email")
      return
    }
    if (step === "email") {
      setStep("password")
      return
    }

    await submitWithCaptcha(async captcha => {
      const res = await api.users.signUp({
        data: {
          ...formData,
          captcha,
          email: formData.email.trim(),
          firstName: formData.firstName.trim(),
          lastName: formData.lastName?.trim() || null,
          token_type: 1,
          platform: platform,
          source: trackingService.getSignupSource(),
          utmParams: trackingService.getUTMParams(),
          /**
           * This is because password1 gets converted to password_1 by lodash. We don't want that.
           * So we skip transformation.
           */
          __noTransform: true,
        },
      })
      if (res.isSuccessful) {
        onAuthSuccess(res.data)
      } else {
        mapErrors(setError, res.errors, [
          ["firstName"],
          ["lastName"],
          ["email"],
          ["password1"],
          ["password2"],
        ])
        if (res.errors.fieldErrors?.invalidCaptcha) {
          triggerCheckboxCaptcha()
          toast.error("Verification failed, please try again later")
        }
        if (res.errors.fieldErrors?.email) {
          setStep("email")
        }
        if (
          res.errors.fieldErrors?.password1 ||
          res.errors.fieldErrors?.password2
        ) {
          resetCaptcha()
          setStep("password")
        }
        if (
          res.errors.fieldErrors?.firstName ||
          res.errors.fieldErrors?.lastName
        ) {
          setStep("name")
        }
      }
    })()
  })

  // TODO(multi-auth): See if we need this. I think we might.
  // useEffect(() => {
  //   const authInfo = getAuthInfo()
  //   if (authInfo) {
  //     handleRedirect()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const password1 = watch("password1")

  if (loading) {
    return (
      <LoadingOverlay>
        <Typography className="mt-2" variant="strong">
          {loadingMessage}
        </Typography>
      </LoadingOverlay>
    )
  }

  const emailAlreadyExists =
    errors.email?.message?.toLowerCase() ===
    "account with this email already exists"

  return (
    <div className={classes.root}>
      {!hideNavBar && (
        <Navbar gutterBottom={false} platform={platform ?? undefined} />
      )}

      <div className={"flex flex-1 flex-col"}>
        <form className={classes.form} onSubmit={onSubmit}>
          {step === "name" && (
            <div>
              <Typography variant="title1">Sign up for Suraasa</Typography>
              <Typography
                className="mb-4 mt-0.5"
                color="onSurface.500"
                variant="subtitle2"
              >
                One Suraasa Account for everything on Suraasa.
              </Typography>
              <TextField
                error={Boolean(errors.firstName)}
                helperText={errors.firstName?.message}
                label="First Name"
                type="text"
                autoFocus
                fullWidth
                {...register("firstName", {
                  required: { value: true, message: "Required" },
                  maxLength: {
                    value: 64,
                    message: "Length cannot be greater than 64",
                  },
                })}
                disabled={isSocialAuthLoading}
              />
              <TextField
                className="mt-1.5"
                error={Boolean(errors.lastName)}
                helperText={errors.lastName?.message}
                label="Last Name"
                type="text"
                fullWidth
                {...register("lastName", {
                  maxLength: {
                    value: 64,
                    message: "Length cannot be greater than 64",
                  },
                })}
                disabled={isSocialAuthLoading}
              />
            </div>
          )}
          {step === "email" && (
            <div>
              <Typography className="mb-3" variant="title1">
                Alright, {formatName(getValues("firstName"))}. Now onto your
                email
              </Typography>
              <TextField
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                label="Email"
                type="email"
                autoFocus
                fullWidth
                {...register("email", {
                  required: { value: true, message: "Required" },
                })}
                disabled={isSocialAuthLoading}
              />

              {emailAlreadyExists && (
                <div className="mt-2">
                  <Button
                    variant="filled"
                    fullWidth
                    component={LinkWithQuery}
                    to={{
                      pathname: signInRoute,
                      search: `?email=${getValues("email")}&autoNext=true`,
                    }}
                  >
                    Would you like to sign in instead?
                  </Button>
                </div>
              )}
            </div>
          )}
          {step === "password" && (
            <div>
              <Typography className="mb-3" variant="title1">
                Great! Let&apos;s set up a password.
              </Typography>
              <TextField
                autoComplete="password1"
                error={Boolean(errors.password1)}
                helperText={errors.password1?.message}
                label="password"
                type="password"
                autoFocus
                fullWidth
                {...register("password1", {
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                  onChange: () => clearErrors("password1"),
                  required: { value: true, message: "Required" },
                })}
                disabled={isSocialAuthLoading}
              />
              <TextField
                autoComplete="password2"
                className="mt-2"
                error={Boolean(errors.password2)}
                helperText={errors.password2?.message}
                label="Confirm Password"
                type="password"
                fullWidth
                {...register("password2", {
                  validate: value =>
                    value === password1 || "The passwords do not match",
                  required: { value: true, message: "Required" },
                })}
                disabled={isSocialAuthLoading}
              />

              <div className="mt-2" id="recaptcha-checkbox-container" />

              <Button
                className="mt-2"
                endAdornment={<ArrowRight />}
                loading={isSubmitting}
                disabled={isSocialAuthLoading}
                type="submit"
                fullWidth
              >
                Create Account
              </Button>
            </div>
          )}
          {step !== "password" && (
            <div className="flex flex-col">
              <Button
                className="mt-2"
                endAdornment={<ArrowRight />}
                loading={isSubmitting}
                type="submit"
                fullWidth
                variant={emailAlreadyExists ? "outlined" : "filled"}
                disabled={isSocialAuthLoading}
              >
                Next
              </Button>
            </div>
          )}
          {socialAuthEnabled && (
            <>
              <div className="my-2 flex items-center justify-center space-x-1">
                <div className="h-[1px] w-full border-b border-[#64748B]" />
                <Typography color="onSurface.600" variant="preTitle">
                  OR
                </Typography>
                <div className="h-[1px] w-full border-b border-[#64748B]" />
              </div>
              <SocialAuth
                onAuthSuccess={onAuthSuccess}
                platform={platform}
                purpose="sign_up"
                product={product}
                setLoading={loading => {
                  setIsSocialAuthLoading(loading)
                }}
              />
            </>
          )}

          <Typography
            className="mt-2"
            color="onSurface.500"
            style={{ textAlign: "center" }}
            variant="smallBody"
          >
            Creating an account means you agree to our{" "}
            <a
              href={getPlatformURL("suraasa", "/terms-of-use")}
              rel="noreferrer"
              style={{ color: theme.colors.primary[500] }}
              target="_blank"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href={getPlatformURL("suraasa", "/privacy-policy")}
              rel="noreferrer"
              style={{ color: theme.colors.primary[500] }}
              target="_blank"
            >
              Privacy Policy
            </a>
          </Typography>
          {step !== "password" && (
            <div className="flex flex-col">
              <div className="mt-2 flex flex-wrap items-center justify-center">
                <Typography color="onSurface.500" variant="smallBody">
                  Already have an account?
                </Typography>
                <Button
                  className={classes.signUpBtn}
                  component={LinkWithQuery}
                  to={signInRoute}
                  variant="link"
                >
                  Sign in instead
                </Button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default SignUp
