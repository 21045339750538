// Reference: https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
import api from "api"
import {
  clearAuthInfo,
  getAuthInfo,
  getCurrentPlatform,
  savePlatformAuthInfo,
} from "utils/auth"

let isTokenRefreshing = false

const promises: (() => void)[] = []

export async function refreshToken(): Promise<void> {
  const authInfo = getAuthInfo()
  if (!authInfo) return

  if (isTokenRefreshing) {
    // getting reference to promise's resolve method so we can call it from outside
    let resolvePromise: () => void
    const currentRequestPromise = new Promise<void>(resolve => {
      resolvePromise = resolve
    })
    // pushing all the promises in the array so they can be resolved later
    promises.push(() => resolvePromise())

    // waiting for the new refresh token from first api call
    return await currentRequestPromise
  }

  isTokenRefreshing = true

  try {
    const res = await api.users.refreshToken({
      data: {
        /**
         * The correct platform is automatically picked up from the URL
         */
        refreshToken: getAuthInfo()?.refreshToken,
      },
      headers: {
        Authorization: null,
      },
    })

    if (res.isSuccessful) {
      const newAuthInfo = {
        ...authInfo,
        accessToken: res.data.accessToken,
        refreshToken: res.data.refreshToken,
      }

      const { platform } = getCurrentPlatform()
      savePlatformAuthInfo(platform, newAuthInfo)
    } else {
      console.info("> Refresh token expired. Logging out.")
      clearAuthInfo()
    }
  } catch (e) {
    console.info("> Refresh token expired. Logging out.")
    clearAuthInfo()
  }

  console.log("refresh token called")
  // TODO(Ideally we should be using 'expiresAt' key to prematurely refresh the token. Before the actual API call)
  window.location.reload()

  // resolving all the pending promises so awaited code can resume execution
  isTokenRefreshing = false
  promises.splice(0).forEach(callBack => {
    callBack()
  })
}
