import {
  Avatar,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import Sidebar from "components/shared/Sidebar"
import {
  List,
  LogOut,
  Menu as MenuIcon,
  ProfileCircle,
  Settings,
} from "iconoir-react"
import React, { useEffect, useRef, useState } from "react"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router"
import { getAuthInfo } from "utils/auth"
import { zIndex } from "utils/config"
import { Platforms } from "utils/constants"
import { buildUserName, getPlatformURL } from "utils/helpers"
import useToggle from "utils/hooks/useToggle"
import { routes } from "utils/routes"

import NavLogo from "./NavLogo"
import { NavbarProps } from "./types"

const NAVBAR_HEIGHT = 62
const useStyles = createUseStyles({
  root: {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: zIndex.navbar,
  },
  nav: {
    height: NAVBAR_HEIGHT,
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    backgroundColor: "white",
    position: "relative",
    zIndex: zIndex.navbar,
    transition: "box-shadow 225ms ease-in-out",
  },
  containerRoot: {
    width: "100%",
  },
  libraryBtn: {
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  enableShadow: {
    boxShadow: "1px 1px 5px 0px #0000001A",
  },
})

const StudentNavbar = ({
  slotStart,
  slotEnd,
  className,
  gutterBottom = true,
}: NavbarProps) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const authInfo = getAuthInfo(Platforms.student)

  // TODO: We don't need sidebar anymore, might just remove it completely
  const renderSidebar = false

  const [enableShadow, setEnableShadow] = useState(false)
  const [sidebarOpen, toggleSidebar] = useToggle(false)

  const navigationRef = useRef<HTMLDivElement>(null)

  const keyDownHandler = (e: React.KeyboardEvent) => {
    // only execute if tab is pressed
    if (e.key !== "Tab") return
    if (navigationRef.current === null) return
    // here we query all focusable elements, customize as your own need
    const focusableModalElements = navigationRef.current.querySelectorAll(
      "a[href], button:not([disabled]), textarea, input, select"
    )

    const firstElement = focusableModalElements[0] as HTMLElement
    const lastElement = focusableModalElements[
      focusableModalElements.length - 1
    ] as HTMLElement

    // if going forward by pressing tab and lastElement is active shift focus to first focusable element
    if (!e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus()
      return e.preventDefault()
    }

    // if going backward by pressing tab and firstElement is active shift focus to last focusable element
    if (e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus()
      e.preventDefault()
    }
  }

  useEffect(() => {
    const handleShadow = () => {
      if (window.scrollY > NAVBAR_HEIGHT) {
        setEnableShadow(true)
      } else {
        setEnableShadow(false)
      }
    }
    window.addEventListener("scroll", handleShadow)
    return () => window.removeEventListener("scroll", handleShadow)
  })

  return (
    <div
      className={classes.root}
      ref={navigationRef}
      role="none"
      onKeyDown={sidebarOpen ? keyDownHandler : undefined}
    >
      <nav
        className={clsx(classes.nav, "flex items-center py-1", className, {
          "mb-6": gutterBottom,
          [classes.enableShadow]: enableShadow,
        })}
      >
        <Container className={classes.containerRoot}>
          <div className={clsx(classes.container)}>
            <div className="flex items-center gap-2">
              {renderSidebar && (
                <IconButton color="black" onClick={() => toggleSidebar()}>
                  <MenuIcon />
                </IconButton>
              )}
              <NavLogo
                href={getPlatformURL("learn", "/")}
                slotStart={slotStart}
              />
            </div>
            <div className="flex gap-1">
              {slotEnd && <div>{slotEnd}</div>}
              {authInfo && (
                <div className="flex items-center sm:gap-1.5">
                  <Button
                    className={classes.libraryBtn}
                    component="a"
                    href={getPlatformURL("learn", "/dashboard/library")}
                    variant="text"
                  >
                    My library
                  </Button>

                  {authInfo && (
                    <div>
                      <Menu
                        align="end"
                        menuButton={
                          <IconButton size="md" type="button">
                            <Avatar
                              color="two"
                              name={buildUserName(authInfo.user)}
                            />
                          </IconButton>
                        }
                      >
                        <MenuItem
                          startAdornment={<ProfileCircle />}
                          onClick={() => {
                            window.location.href = getPlatformURL(
                              "learn",
                              "/profile"
                            )
                          }}
                        >
                          Profile
                        </MenuItem>
                        <MenuItem
                          startAdornment={<List />}
                          onClick={() => {
                            window.location.href = getPlatformURL(
                              "learn",
                              "/orders"
                            )
                          }}
                        >
                          Orders
                        </MenuItem>
                        <MenuItem
                          startAdornment={<Settings />}
                          onClick={() => {
                            window.location.href = getPlatformURL(
                              "learn",
                              "/settings"
                            )
                          }}
                        >
                          Settings
                        </MenuItem>
                        <MenuItem
                          color="critical"
                          startAdornment={<LogOut />}
                          onClick={() => {
                            navigate(routes.logout)
                          }}
                        >
                          Sign out
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Container>
      </nav>
      {renderSidebar && <Sidebar open={sidebarOpen} toggle={toggleSidebar} />}
    </div>
  )
}

export default StudentNavbar
