import { Outlet } from "react-router"
import { useLogoutMessaging } from "utils/hooks/useLogout"

import FeatureToggleManagerProvider from "./FeatureToggleProvider"

const AppWrapper = () => {
  useLogoutMessaging()

  return (
    <FeatureToggleManagerProvider>
      <Outlet />
    </FeatureToggleManagerProvider>
  )
}

export default AppWrapper
