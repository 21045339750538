import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"
import { getFingerprint } from "utils/fingerprint"

import {
  AuthData,
  HybridLoginType,
  RefreshTokenResponse,
  ResetPassword,
  Submission,
  UpdateUserPreferenceResponse,
  UserOTP,
  UserPreference,
  VerifyUserOTP,
} from "./types"
import { urls } from "./urls"

export default {
  socialAuth: async ({ data }: Config): Promise<APIResponse<AuthData>> => {
    try {
      const res = await axios.post(urls.auth.socialAuth(), data, {
        headers: {
          "device-id": await getFingerprint(),
          platform: "suraasa-web",
        },
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  login: async ({ data }: Config): Promise<APIResponse<AuthData>> => {
    try {
      const res = await axios.post(urls.auth.login(), data, {
        headers: {
          "device-id": await getFingerprint(),
          platform: "suraasa-web",
        },
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getUserDetails: async ({
    data,
  }: Config): Promise<APIResponse<HybridLoginType>> => {
    try {
      const res = await axios.post(urls.auth.getUserDetails(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  logout: async (): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.auth.logout())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  refreshToken: async ({
    data,
    ...options
  }: Config): Promise<APIResponse<RefreshTokenResponse>> => {
    try {
      const res = await axios.post(urls.auth.refreshToken(), data, options)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },

  generateAuthCode: async ({
    data,
  }: Config): Promise<APIResponse<{ code: string; expiresAt: string }>> => {
    try {
      const res = await axios.post(urls.auth.generateAuthCode(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  signUp: async ({ data }: Config): Promise<APIResponse<AuthData>> => {
    try {
      const res = await axios.post(urls.auth.signUp(), data, {
        headers: {
          "device-id": await getFingerprint(),
        },
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  forgotPassword: async ({
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.auth.forgotPassword(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  changePassword: async ({
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.auth.changePassword(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  verifyPasswordSignature: async ({
    data,
    urlParams: { uuid, token, timestamp },
  }: ConfigWithURLParams): Promise<APIResponse<ResetPassword>> => {
    try {
      const res = await axios.get(
        urls.auth.resetPassword(uuid, token, timestamp),
        data
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  /**
   * This api is used to normally reset password
   * but also in the case where user has to set his password for first time
   */
  resetPassword: async ({
    data,
    urlParams: { uuid, token, timestamp },
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType | AuthData>> => {
    try {
      const res = await axios.post(
        urls.auth.resetPassword(uuid, token, timestamp),
        data,
        {
          headers: {
            "device-id": await getFingerprint(),
            platform: "suraasa-web",
          },
        }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  sendUserOTP: async ({ data }: Config): Promise<APIResponse<UserOTP>> => {
    try {
      const res = await axios.post(urls.auth.sendUserOTP(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  verifyUserOTP: async ({
    data,
  }: Config): Promise<APIResponse<VerifyUserOTP>> => {
    try {
      const res = await axios.post(urls.auth.verifyUserOTP(), data, {
        headers: {
          "device-id": await getFingerprint(),
          platform: "suraasa-web",
        },
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  skillProfile: {
    getPublicSubmission: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Submission>> => {
      try {
        const res = await axios.get(
          urls.skills.getPublicSubmission(urlParams.submissionId)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    downloadAttachment: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponse<{ name: string; url: string }>
    > => {
      try {
        const res = await axios.get(
          urls.skills.downloadAttachment(urlParams.attachmentId)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  getPublicSkillProfile: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<any>> => {
    try {
      const res = await axios.get(
        urls.skills.publicSkillProfile(urlParams.username)
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  getPrivateSkillProfile: async (): Promise<APIResponse<any>> => {
    try {
      const res = await axios.get(urls.skills.privateSkillProfile())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  toggleVisibility: async ({ data }: Config): Promise<APIResponse<any>> => {
    try {
      const res = await axios.put(urls.skills.toggleVisibility(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getUserPreferences: async (): Promise<APIResponse<UserPreference[]>> => {
    try {
      const res = await axios.get(urls.config.preferences())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  updateUserPreferences: async ({
    data,
  }: Config): Promise<APIResponse<UpdateUserPreferenceResponse[]>> => {
    try {
      const res = await axios.post(urls.config.preferences(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  closedBeta: {
    verifySignature: async ({
      urlParams: { id, token },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.get(urls.closedBeta.verifySignature(id, token))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    createPassword: async ({
      data,
      urlParams: { id, token },
    }: ConfigWithURLParams): Promise<APIResponse<AuthData>> => {
      try {
        const res = await axios.post(
          urls.closedBeta.verifySignature(id, token),
          data,
          {
            headers: {
              "device-id": await getFingerprint(),
              platform: "suraasa-web",
            },
          }
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
