import axios from "api/axios"
import { Profile } from "api/resources/profile/types"
import { AuthData } from "api/resources/users/types"
import { APIResponse, Config, NoContentType } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"
import { getFingerprint } from "utils/fingerprint"

import { School } from "./types"
import { urls } from "./urls"

export default {
  listAccessibleSchools: async (): Promise<APIResponse<School[]>> => {
    try {
      const res = await axios.get(urls.school.listAccessibleSchools())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  create: async ({ data }: Config): Promise<APIResponse<School>> => {
    try {
      const res = await axios.post(urls.school.list(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  retrieve: async ({ params }: Config): Promise<APIResponse<Profile>> => {
    try {
      const res = await axios.get(urls.school.retrieve(), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  update: async ({ data }: Config): Promise<APIResponse<Profile>> => {
    try {
      const res = await axios.put(urls.school.list(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  verify: async (): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.put(urls.school.verify())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  signUp: async ({ data }: Config): Promise<APIResponse<AuthData>> => {
    try {
      const res = await axios.post(urls.school.signup(), data, {
        headers: {
          "device-id": await getFingerprint(),
        },
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
