import {
  Auth,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth"
import { firebaseApp } from "services/firebase/init"

export const getFirebaseAuth = (): Auth => {
  const app = firebaseApp

  return getAuth(app)
}

export const googleAuthHandler = async () => {
  const provider = new GoogleAuthProvider()
  provider.addScope("https://www.googleapis.com/auth/userinfo.email")
  provider.addScope("https://www.googleapis.com/auth/userinfo.profile")
  provider.addScope("openid")

  const auth = getFirebaseAuth()

  try {
    const result = await signInWithPopup(auth, provider)
    const credential = GoogleAuthProvider.credentialFromResult(result)
    return { ...result, accessToken: credential?.accessToken }
  } catch (error) {
    return null
  }
}

export const facebookAuthHandler = async () => {
  const provider = new FacebookAuthProvider()
  provider.addScope("openid")
  provider.addScope("email")

  const auth = getFirebaseAuth()

  try {
    const result = await signInWithPopup(auth, provider)
    const credential = FacebookAuthProvider.credentialFromResult(result)
    return { ...result, accessToken: credential?.accessToken }
  } catch (error) {
    return null
  }
}
export const appleAuthHandler = async () => {
  const provider = new OAuthProvider("apple.com")
  provider.addScope("openid")
  provider.addScope("email")
  provider.addScope("name")

  const auth = getFirebaseAuth()

  try {
    const result = await signInWithPopup(auth, provider)
    const credential = OAuthProvider.credentialFromResult(result)

    return { ...result, accessToken: credential?.idToken }
  } catch (error) {
    return null
  }
}
