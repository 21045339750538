import { captureException } from "@sentry/react"
import { Button, Container, TextField, Typography } from "@suraasa/placebo-ui"
import api from "api"
import clsx from "clsx"
import Footer from "components/shared/Footer"
import LoadingOverlay from "components/shared/LoadingOverlay"
import Navbar from "components/shared/Navbar"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { useNavigate, useSearchParams } from "react-router-dom"
import { clearAuthInfo, getAuthInfo, savePlatformAuthInfo } from "utils/auth"
import { Platforms, Product, SVP_PLATFORM_URL } from "utils/constants"
import { handleErrors } from "utils/helpers"
import useToggle from "utils/hooks/useToggle"
import { routes } from "utils/routes"
import toast from "utils/toast"

const useStyles = createUseStyles(theme => ({
  root: {
    background: theme.colors.common.white[500],
    height: "100vh",
  },
  form: {
    width: "340px",
  },
}))

type FormData = {
  email: string
  password: string
}

const SVPLogin = () => {
  const email = useSearchParams()[0].get("email")
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormData>({
    defaultValues: {
      email: email || "",
    },
  })

  const classes = useStyles()

  const [loading, toggleLoading] = useToggle(true)
  const [loadingMessage, setLoadingMessage] = useState("")

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const platform: Platforms | null = Platforms.svp

  const openLink = (code: string) => {
    const redirectUrl =
      searchParams.get("redirect-url") || `${SVP_PLATFORM_URL}/sso`

    if (redirectUrl) {
      const url = new URL(redirectUrl)
      url.searchParams.append("code", code)
      window.location.href = url.href
    } else {
      captureException("VITE_SVP_PLATFORM_URL not found")
    }
  }

  const isValidPlatform = Platforms.svp === (platform as Platforms)

  const generateAuthCode = async (platformKey: Platforms) => {
    toggleLoading(true)
    setLoadingMessage("You will be redirected shortly")
    const res = await api.users.generateAuthCode({
      data: { platform: platformKey },
    })
    if (res.isSuccessful) {
      const { code } = res.data
      openLink(code)
    } else {
      toggleLoading(false)
      setLoadingMessage("")
      if (res.errors.message) {
        toast.error(res.errors.message)
      }
      if (res.errors.fieldErrors?.platform) {
        toast.error(res.errors.fieldErrors.platform)
      }
    }
  }

  useEffect(() => {
    const handleLogin = () => {
      const auth = getAuthInfo(Platforms.svp)
      if (auth) {
        if (isValidPlatform) {
          if (auth?.platform === Platforms.svp) {
            return generateAuthCode(platform)
          }
        }
      }
      toggleLoading(false)
    }
    handleLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = handleSubmit(async data => {
    if (getAuthInfo(Platforms.svp)) {
      await api.users.logout()
      clearAuthInfo(Platforms.svp)
    }
    const res = await api.users.login({
      data: { ...data, product: Product.school },
    })
    if (res.isSuccessful) {
      savePlatformAuthInfo(Platforms.svp, res.data)

      if (isValidPlatform) {
        await generateAuthCode(platform)
      } else {
        navigate(routes.profile)
      }
    } else {
      handleErrors(setError, res.errors)
    }
  })

  if (loading) {
    return (
      <LoadingOverlay>
        <Typography className="mt-2" variant="strong">
          {loadingMessage}
        </Typography>
      </LoadingOverlay>
    )
  }

  return (
    <div className={clsx(classes.root, "flex flex-col")}>
      <Navbar platform={Platforms.svp} />
      <Container className="grow">
        <div className="flex flex-col items-center">
          <Typography className="my-5" textAlign="center" variant="title1">
            Sign in to School Verification Portal
          </Typography>
          <form className={classes.form} onSubmit={onSubmit}>
            <TextField
              className="mb-3.5"
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
              label="Email ID"
              placeholder="Enter Email ID"
              fullWidth
              {...register("email", {
                required: { value: true, message: "Required" },
              })}
            />
            <TextField
              className="mb-7"
              error={Boolean(errors.password)}
              helperText={errors.password && errors.password.message}
              label="Password"
              placeholder="Enter Password"
              type="password"
              fullWidth
              {...register("password", {
                required: { value: true, message: "Required" },
              })}
            />
            <Button loading={isSubmitting} type="submit" fullWidth>
              Log In
            </Button>
          </form>
        </div>
      </Container>
      <Footer />
    </div>
  )
}

export default SVPLogin
