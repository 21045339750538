import { Navigate } from "react-router-dom"

type Props = {
  to: string
  search?: Record<string, string>
}

const Redirect = (props: Props) => {
  const searchParams = new URLSearchParams(window.location.search)

  const combined = new URLSearchParams({
    ...Object.fromEntries(searchParams),
    ...(props.search ?? {}),
  })

  return (
    <Navigate
      to={{
        pathname: props.to,
        search: combined.toString(),
      }}
      replace
    />
  )
}

export default Redirect
