import { Button, TextField, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import AuthLayout from "components/auth/partner/AuthLayout"
import DarkOverlayLoading from "components/shared/DarkOverlayLoading"
import React from "react"
import { useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { Platforms, Product } from "utils/constants"
import { handleErrors } from "utils/helpers"
import { useLogin } from "utils/hooks/useLogin"
import { routes } from "utils/routes"

const useStyles = createUseStyles({
  root: {
    width: 358,
    maxWidth: "100%",
    "@media only screen and (max-width: 400px)": {
      width: "100%",
    },
  },
})

type FormData = {
  email: string
  password: string
}

type Props = {
  onSuccess?: (code: string) => void
}

const PartnerLogin = ({ onSuccess }: Props) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormData>()

  const classes = useStyles()

  const { handleSubmit: onSubmit, loading } = useLogin({
    options: {
      initialLoading: true,
      processRedirect: !onSuccess,
    },
    product: Product.partner,
    platform: Platforms.olympiadPartner,
    onSuccess,
  })

  const submitForm = handleSubmit(async data => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const errors = await onSubmit(data)
    if (errors) {
      handleErrors(setError, errors)
    }
  })

  return (
    <DarkOverlayLoading
      enabled={loading}
      message={<Typography variant="largeTitle">Authenticating</Typography>}
      size="lg"
    >
      <AuthLayout>
        <div className="flex flex-col">
          <form
            className={clsx("flex flex-col gap-2 mt-3 mx-auto", classes.root)}
            onSubmit={submitForm}
          >
            <TextField
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              label="Email Address"
              type="email"
              fullWidth
              {...register("email", {
                required: { value: true, message: "Required" },
              })}
            />
            <TextField
              autoComplete="password"
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              label="Password"
              type="password"
              fullWidth
              {...register("password", {
                required: { value: true, message: "Required" },
              })}
            />
            <Button
              className="mt-3"
              loading={isSubmitting}
              type="submit"
              fullWidth
            >
              Sign in
            </Button>
          </form>
          <div className="mt-2 flex justify-center">
            <Button
              component={Link}
              to={routes.partner.forgotPassword}
              variant="link"
            >
              Forgot Password
            </Button>
          </div>
        </div>
      </AuthLayout>
    </DarkOverlayLoading>
  )
}

export default PartnerLogin
