import DefaultNavbar from "components/shared/Navbar/DefaultNavbar"
import PartnerNavbar from "components/shared/Navbar/PartnerNavbar"
import SchoolNavbar from "components/shared/Navbar/SchoolNavbar"
import StudentNavbar from "components/shared/Navbar/StudentNavbar"
import { NavbarProps } from "components/shared/Navbar/types"
import { getCurrentPlatform } from "utils/auth"
import { Platforms } from "utils/constants"

type Props = {
  /**
   * If this platform is provided, it will render the platform specific navbar
   * regardless of the platform the user is logged in as.
   */
  platform?: Platforms | null
} & NavbarProps

const Navbar = ({ platform: userProvidedPlatform, ...props }: Props) => {
  const platform = userProvidedPlatform || getCurrentPlatform().platform

  switch (platform) {
    case Platforms.student:
      return <StudentNavbar {...props} />
    case Platforms.school:
      return <SchoolNavbar {...props} />
    case Platforms.svp:
      return <SchoolNavbar {...props} slotEnd={null} />
    case Platforms.olympiadPartner:
      return <PartnerNavbar {...props} slotEnd={null} />
    default:
      return <DefaultNavbar {...props} />
  }
}

export default Navbar
