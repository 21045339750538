import { useParams, useSearchParams } from "react-router-dom"

type Props = {
  to: string

  params?: string[]
  search?: Record<string, string>
}

const ExternalNavigate = ({ to, params, search = {} }: Props) => {
  const urlParams = useParams()
  const [searchParams] = useSearchParams()

  params?.forEach(param => {
    to = to.replace(`:${param}`, urlParams[param] as string)
  })

  const url = new URL(to)
  const queryParams = new URLSearchParams({
    ...Object.fromEntries(url.searchParams),
    ...Object.fromEntries(searchParams),
    ...Object.fromEntries(Object.entries(search)),
  })

  queryParams.forEach((value, key) => {
    url.searchParams.set(key, value)
  })

  window.location.replace(url.toString())

  return null
}

export default ExternalNavigate
