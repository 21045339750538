import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSolisURL = getServiceURL(ServicePrefix.solis)
const getGravityURL = getServiceURL(ServicePrefix.gravity)

export const urls = validateUrls({
  profile: {
    detail: () => getSolisURL(`/v1/profile/`),
    resume: () => getSolisURL(`/v1/profile/download-resume/`),
    public: username =>
      getSolisURL(`/v1/profile/public/${username ? `${username}/` : ""}`),
  },

  languages: {
    list: username =>
      getSolisURL(
        `/v1/profile/public/languages/${username ? `${username}/` : ""}`
      ),
    create: () => getSolisURL("/v1/profile/languages/"),
    detail: id => getSolisURL(`/v1/profile/languages/${id}/`),
  },

  locations: {
    create: () => getSolisURL("/v1/profile/preferred-work-locations/"),
    detail: id => getSolisURL(`/v1/profile/preferred-work-locations/${id}/`),
  },

  awards: {
    detail: id => getSolisURL(`/v1/profile/awards/${id}/`),
    list: () => getSolisURL("/v1/profile/awards/"),
  },

  publications: {
    detail: id => getSolisURL(`/v1/profile/publications/${id}/`),
    list: () => getSolisURL("/v1/profile/publications/"),
  },

  projects: {
    detail: id => getSolisURL(`/v1/profile/projects/${id}/`),
    list: () => getSolisURL("/v1/profile/projects/"),
  },

  testScores: {
    detail: id => getSolisURL(`/v1/profile/test-scores/${id}/`),
    list: () => getSolisURL("/v1/profile/test-scores/"),
  },

  subjects: {
    create: () => getSolisURL("/v1/profile/subjects/"),
    detail: id => getSolisURL(`/v1/profile/subjects/${id}/`),
  },

  interests: {
    detail: id => getSolisURL(`/v1/profile/interests/${id}/`),
    create: () => getSolisURL("/v1/profile/interests/"),
    list: username =>
      getSolisURL(
        `/v1/profile/public/interests/${username ? `${username}/` : ""}`
      ),
  },

  achievements: {
    list: username =>
      getSolisURL(
        `/v1/profile/public/achievements/${username ? `${username}/` : ""}`
      ),
  },

  email: {
    detail: id => getSolisURL(`/v1/users/emails/${id}/`),
    list: () => getSolisURL(`/v1/users/emails/`),
    create: () => getSolisURL(`/v1/users/emails/unverified/`),
    verify: id => getSolisURL(`/v1/users/emails/unverified/${id}/verify/`),
  },
  workExperiences: {
    detail: id => getSolisURL(`/v1/profile/work-experiences/${id}/`),
    list: username =>
      getSolisURL(
        `/v1/profile/public/work-experiences/${username ? `${username}/` : ""}`
      ),
    create: () => getSolisURL("/v1/profile/work-experiences/"),
  },
  academics: {
    list: username =>
      getSolisURL(
        `/v1/profile/public/academics/${username ? `${username}/` : ""}`
      ),
  },
  qualificationLevels: {
    list: () => getGravityURL("/qualification-levels/"),
  },
  qualification: {
    create: () => getSolisURL(`/v1/profile/qualifications/`),
    detail: id => getSolisURL(`/v1/profile/qualifications/${id}/`),
    evidence: id => getSolisURL(`/v1/profile/qualifications/${id}/evidences`),
  },
  certification: {
    create: () => getSolisURL(`/v1/profile/certifications/`),
    detail: id => getSolisURL(`/v1/profile/certifications/${id}/`),
    evidence: id => getSolisURL(`/v1/profile/certifications/${id}/evidences`),
  },
  videoPortfolio: {
    detail: () => `/profile/video-portfolio/`,
    retrieve: username =>
      `/profile/public/${username ? `${username}/` : ""}video-portfolio/`,
  },
  skillEvidences: {
    overview: username =>
      username
        ? `analytics/public/${username}/skill-evidences/`
        : `/analytics/skill-evidences/`,
  },
  block: {
    list: () => `/user/block/`,
    delete: id => `/user/block/${id}/`,
  },
})
