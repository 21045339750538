import { AuthData } from "api/resources/users/types"
import { transformKeys } from "api/utils"
import camelCase from "lodash/camelCase"
import { createSearchParams } from "react-router-dom"
import { BROWSER_STORAGE_KEYS, Platforms, Product } from "utils/constants"

import { routes } from "./routes"
import { LocalStorage, SessionStorage } from "./storageFactory"

const getPlatformAuthKey = (platform: Platforms) => {
  return BROWSER_STORAGE_KEYS.auth(platform)
}

const getPlatformAuthInfo = (platform: Platforms) => {
  const key = getPlatformAuthKey(platform)
  return LocalStorage.getItem(key)
}

export function savePlatformAuthInfo(platform: Platforms, authInfo: AuthData) {
  const newAuthInfoObj: AuthData = transformKeys(authInfo, camelCase)
  LocalStorage.setItem(
    getPlatformAuthKey(platform),
    JSON.stringify(newAuthInfoObj)
  )
}

export function getAuthInfo(platformOverride?: Platforms) {
  const platform = platformOverride ?? getCurrentPlatform().platform
  const authInfo = getPlatformAuthInfo(platform)

  if (authInfo !== null) {
    const newAuthInfoObj: AuthData & { platform: Platforms } = transformKeys(
      { ...JSON.parse(authInfo), platform },
      camelCase
    )
    return newAuthInfoObj
  }

  return null
}

export function clearAuthInfo(platformOverride?: Platforms) {
  const platform = platformOverride ?? getCurrentPlatform().platform

  const auth = getPlatformAuthKey(platform)
  LocalStorage.removeItem(auth)
  SessionStorage.removeItem(auth)
}

export const isValidPlatform = (
  platform: string | null,
  validPlatformList = Object.values(Platforms)
): platform is Platforms =>
  platform ? validPlatformList.includes(platform as Platforms) : false

export const getProbablePlatform = (
  product: Product,
  platform?: Platforms | null
) => {
  switch (product) {
    case Product.learning:
      if (!platform) return Platforms.student
      return platform
    case Product.school:
      if (!platform) return Platforms.school
      return platform
    case Product.partner:
      if (!platform) return Platforms.olympiadPartner
      return platform
    case Product.internal:
      if (!platform) return Platforms.student
      return platform
    case Product.center:
      if (!platform) return Platforms.centreAdmin
      return platform

    default:
      throw new Error("This shouldn't be reached")
  }
}

const getProbablePlatformByURL = () => {
  const url = new URL(window.location.href)
  const firstSegment = url.pathname.split("/").filter(Boolean)[0]
  const base = firstSegment ? `/${firstSegment}` : "/"

  switch (base) {
    case routes.svp.base:
      return Platforms.svp
    case routes.internal.base:
      return Platforms.serviceAdmin
    case routes.centre.base:
      return Platforms.centreAdmin
    case routes.school.base:
      return Platforms.school
    case routes.partner.base:
      return Platforms.olympiadPartner
    default:
      return Platforms.student
  }
}

export function getAuthRoute(options: {
  search?: Record<string, string | string[]>
  product: Product
  platform?: Platforms | null
  key: "login" | "forgotPassword"
}) {
  options.search = options.search ?? {}
  const platform = getProbablePlatform(options.product, options.platform)

  const { key } = options

  let route: string = routes[key]

  switch (platform) {
    case Platforms.student:
      route = routes[key]
      break

    case Platforms.centerHead:
      route = routes.centre[key]
      options.search.platform = Platforms.centerHead
      break

    case Platforms.centreAdmin:
      route = routes.centre[key]
      options.search.platform = Platforms.centreAdmin
      break

    case Platforms.professor:
    case Platforms.iqa:
      route = routes[key]
      options.search.platform = Platforms.professor
      break

    case Platforms.serviceAdmin:
      route = routes.internal[key]
      options.search.platform = Platforms.serviceAdmin
      break

    case Platforms.svp:
      route = routes.svp[key]
      options.search.platform = Platforms.svp
      break

    case Platforms.school:
      route = routes.school[key]
      options.search.platform = Platforms.school
      break

    case Platforms.apiDocs:
      route = routes.internal[key]
      options.search.platform = Platforms.apiDocs
      break

    case Platforms.olympiadPartner:
      route = routes.partner[key]
      options.search.platform = Platforms.olympiadPartner
      break

    case Platforms.applicationFormAdmin:
    case Platforms.admissionsCounsellor:
      route = routes.internal[key]
      options.search.platform = platform
      break

    default:
      throw new Error(
        `Invalid platform supplied to getAuthRoute. Platform: ${platform}; Product: ${options.product}`
      )
  }

  const searchParams = createSearchParams(options.search).toString()

  return `${route}?${searchParams}`
}

export const isValidOrigin = (origin: string) => {
  const isSuraasaURL = (url: URL) => {
    const [TLD, domain] = url.hostname.split(".").reverse()
    return domain === "suraasa" && TLD === "com"
  }

  const isNetlifyURL = (url: URL) => {
    const [TLD, domain] = url.hostname.split(".").reverse()
    return domain === "netlify" && TLD === "app"
  }

  const isLocalhostURL = (url: URL) => {
    const [TLD, domain] = url.hostname.split(".").reverse()
    return TLD === "localhost" && !domain
  }

  if (import.meta.env.VITE_ALLOW_LOCALHOST_SSO_MESSAGING === "true") {
    return (
      isLocalhostURL(new URL(origin)) ||
      isSuraasaURL(new URL(origin)) ||
      isNetlifyURL(new URL(origin))
    )
  }

  return isSuraasaURL(new URL(origin))
}

export const getCurrentPlatform = () => {
  const params = new URLSearchParams(window.location.search)

  const rawPlatform = params.get("platform")
  const isValid = isValidPlatform(rawPlatform)

  if (!rawPlatform) console.warn("Platform not found in URL, using default")
  if (rawPlatform && !isValid) console.warn("Invalid platform, using default")

  const platform = (() => {
    if (rawPlatform && isValidPlatform(rawPlatform)) return rawPlatform
    const probablePlatform = getProbablePlatformByURL()

    return probablePlatform
  })()

  if (rawPlatform !== platform) {
    console.log("Updating platform in URL")
    params.set("platform", platform)
    window.location.search = params.toString()
  }

  return { platform, product: getProductFromPlatform(platform) }
}

const getProductFromPlatform = (platform: Platforms): Product => {
  switch (platform) {
    case Platforms.student:
    case Platforms.professor:
    case Platforms.apiDocs:
    case Platforms.iqa:
      return Product.learning
    case Platforms.centerHead:
    case Platforms.centreAdmin:
      return Product.center
    case Platforms.serviceAdmin:
    case Platforms.applicationFormAdmin:
    case Platforms.admissionsCounsellor:
      return Product.internal
    case Platforms.svp:
    case Platforms.school:
      return Product.school
    case Platforms.olympiadPartner:
      return Product.partner
  }
}
