import { createContext, useContext, useEffect, useState } from "react"
import type { FeatureToggleType } from "utils/featureToggleManager"
import {
  featureToggleDefaultValues,
  FeatureToggleManager,
} from "utils/featureToggleManager"

const FeatureToggleContext = createContext<FeatureToggleType>(
  featureToggleDefaultValues
)

export const useFeatureToggle = () => useContext(FeatureToggleContext)

const FeatureToggleManagerProvider = (props: { children: React.ReactNode }) => {
  const [featureToggleConfig, setFeatureToggleConfig] =
    useState<FeatureToggleType>(featureToggleDefaultValues)

  useEffect(() => {
    FeatureToggleManager.init()
    setFeatureToggleConfig(FeatureToggleManager.getConfig())

    FeatureToggleManager.syncFromFirebase().then(() => {
      setFeatureToggleConfig(FeatureToggleManager.getConfig())
    })
  }, [])

  return (
    <FeatureToggleContext.Provider value={featureToggleConfig}>
      {props.children}
    </FeatureToggleContext.Provider>
  )
}

export default FeatureToggleManagerProvider
