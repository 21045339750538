import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getDopplerURL = getServiceURL(ServicePrefix.doppler)

export const urls = validateUrls({
  notification: {
    detail: notificationId =>
      getDopplerURL(`/v1/inapp-notifications/${notificationId}/`),
  },
})
