import { Link, LinkProps, useLocation } from "react-router-dom"

const LinkWithQuery = ({ children, to, ...props }: LinkProps) => {
  const { search } = useLocation()

  if (typeof to === "string")
    return (
      <Link to={`${to}${search}`} {...props}>
        {children}
      </Link>
    )

  const existingSearchParams = new URLSearchParams(search)
  const passedSearchParams = new URLSearchParams(to.search)
  for (const [key, value] of passedSearchParams.entries()) {
    existingSearchParams.set(key, value)
  }

  return (
    <Link
      to={{
        ...to,
        search: existingSearchParams.toString(),
      }}
      {...props}
    >
      {children}
    </Link>
  )
}

export default LinkWithQuery
