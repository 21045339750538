import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import {
  Award,
  BlockedUser,
  Certification,
  Email,
  Evidence,
  Interest,
  LanguageResponse,
  Profile,
  Project,
  Publication,
  Qualification,
  QualificationLevel,
  SkillEvidences,
  SubjectResponse,
  TestScore,
  WorkExperienceType,
  WorkLocationInterest,
} from "./types"
import { urls } from "./urls"

export default {
  retrieve: async ({
    urlParams,
  }: ConfigWithURLParams<"username">): Promise<APIResponse<Profile>> => {
    try {
      const res = await axios.get(urls.profile.public(urlParams.username))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  update: async ({ data, headers }: Config): Promise<APIResponse<Profile>> => {
    try {
      const res = await axios.put(urls.profile.detail(), data, { headers })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  downloadResume: async (): Promise<APIResponse<Profile>> => {
    try {
      const res = await axios.get(urls.profile.resume(), {
        responseType: "blob",
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listAchievements: async ({
    urlParams,
  }: ConfigWithURLParams<"username">): Promise<
    APIResponse<{
      publications: Publication[]
      awards: Award[]
      testScores: TestScore[]
      projects: Project[]
    }>
  > => {
    try {
      const res = await axios.get(urls.achievements.list(urlParams.username))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  languages: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponse<LanguageResponse[]>
    > => {
      try {
        const res = await axios.get(urls.languages.list(urlParams.username))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    create: async ({
      data,
    }: Config): Promise<APIResponse<LanguageResponse>> => {
      try {
        const res = await axios.post(urls.languages.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams<"id">): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.languages.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  locations: {
    create: async ({
      data,
    }: Config): Promise<APIResponse<WorkLocationInterest>> => {
      try {
        const res = await axios.post(urls.locations.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams<"id">): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.locations.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  awards: {
    create: async ({ data }: Config): Promise<APIResponse<Award>> => {
      try {
        const res = await axios.post(urls.awards.list(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.awards.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Award>> => {
      try {
        const res = await axios.put(urls.awards.detail(urlParams.id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Award>> => {
      try {
        const res = await axios.get(urls.awards.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  publications: {
    create: async ({ data }: Config): Promise<APIResponse<Publication>> => {
      try {
        const res = await axios.post(urls.publications.list(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Publication>> => {
      try {
        const res = await axios.get(urls.publications.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Publication>> => {
      try {
        const res = await axios.put(
          urls.publications.detail(urlParams.id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.publications.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  testScores: {
    create: async ({ data }: Config): Promise<APIResponse<TestScore>> => {
      try {
        const res = await axios.post(urls.testScores.list(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<TestScore>> => {
      try {
        const res = await axios.get(urls.testScores.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<TestScore>> => {
      try {
        const res = await axios.put(urls.testScores.detail(urlParams.id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.testScores.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  projects: {
    create: async ({ data }: Config): Promise<APIResponse<Project>> => {
      try {
        const res = await axios.post(urls.projects.list(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Project>> => {
      try {
        const res = await axios.get(urls.projects.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Project>> => {
      try {
        const res = await axios.put(urls.projects.detail(urlParams.id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.projects.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  interests: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponse<{
        preferredWorkLocations: WorkLocationInterest[]
        interests: Interest[]
        subjects: SubjectResponse[]
      }>
    > => {
      try {
        const res = await axios.get(urls.interests.list(urlParams.username))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    create: async ({ data }: Config): Promise<APIResponse<Interest>> => {
      try {
        const res = await axios.post(urls.interests.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.interests.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  subjects: {
    create: async ({ data }: Config): Promise<APIResponse<SubjectResponse>> => {
      try {
        const res = await axios.post(urls.subjects.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.subjects.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  emails: {
    list: async (): Promise<APIResponse<Email[]>> => {
      try {
        const res = await axios.get(urls.email.list())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Email[]>> => {
      try {
        const res = await axios.delete(urls.email.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Email[]>> => {
      try {
        const res = await axios.put(urls.email.detail(urlParams.id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    create: async ({ data }: Config): Promise<APIResponse<Email>> => {
      try {
        const res = await axios.post(urls.email.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    verify: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Email>> => {
      try {
        const res = await axios.post(urls.email.verify(urlParams.id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  listAcademics: async ({
    urlParams,
  }: ConfigWithURLParams<"username">): Promise<
    APIResponse<{
      certifications: Certification[]
      qualifications: Qualification[]
    }>
  > => {
    try {
      const res = await axios.get(urls.academics.list(urlParams.username))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listQualificationLevels: async (): Promise<
    APIResponse<QualificationLevel[]>
  > => {
    try {
      const res = await axios.get(urls.qualificationLevels.list())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  certification: {
    create: async ({ data }: Config): Promise<APIResponse<Certification>> => {
      try {
        const res = await axios.post(urls.certification.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.certification.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Certification>> => {
      try {
        const res = await axios.put(
          urls.certification.detail(urlParams.id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponse<Certification & { evidences: Evidence[] }>
    > => {
      try {
        const res = await axios.get(urls.certification.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    evidence: {
      create: async ({
        data,
        urlParams,
      }: ConfigWithURLParams): Promise<APIResponse<Evidence[]>> => {
        try {
          const res = await axios.post(
            urls.certification.evidence(urlParams.id),
            data
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      delete: async ({
        data,
        urlParams,
      }: ConfigWithURLParams): Promise<APIResponse<Certification>> => {
        try {
          const res = await axios.delete(
            urls.certification.evidence(urlParams.id),
            {
              data,
            }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
    },
  },

  qualification: {
    create: async ({ data }: Config): Promise<APIResponse<Qualification>> => {
      try {
        const res = await axios.post(urls.qualification.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.qualification.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Qualification>> => {
      try {
        const res = await axios.put(
          urls.qualification.detail(urlParams.id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponse<Qualification & { evidences: Evidence[] }>
    > => {
      try {
        const res = await axios.get(urls.qualification.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    evidence: {
      create: async ({
        data,
        urlParams,
      }: ConfigWithURLParams): Promise<APIResponse<Evidence[]>> => {
        try {
          const res = await axios.post(
            urls.qualification.evidence(urlParams.id),
            data
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      delete: async ({
        data,
        urlParams,
      }: ConfigWithURLParams): Promise<APIResponse<Certification>> => {
        try {
          console.log(data)
          const res = await axios.delete(
            urls.qualification.evidence(urlParams.id),
            {
              data,
            }
          )
          return formatSuccessResponse(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
    },
  },

  videoPortfolio: {
    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponse<{ videoPortfolio: string | null }>
    > => {
      try {
        const res = urlParams.username
          ? await axios.get(urls.videoPortfolio.retrieve(urlParams.username))
          : await axios.get(urls.videoPortfolio.detail())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    create: async ({
      data,
      headers,
    }: Config): Promise<APIResponse<{ videoPortfolio: string }>> => {
      try {
        const res = await axios.post(
          urls.videoPortfolio.detail(),
          data,
          headers
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async (): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.videoPortfolio.detail())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  workExperiences: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponse<WorkExperienceType[]>
    > => {
      try {
        const res = await axios.get(
          urls.workExperiences.list(urlParams.username)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    create: async ({
      data,
    }: Config): Promise<APIResponse<WorkExperienceType>> => {
      try {
        const res = await axios.post(urls.workExperiences.create(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(
          urls.workExperiences.detail(urlParams.id)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<WorkExperienceType>> => {
      try {
        const res = await axios.put(
          urls.workExperiences.detail(urlParams.id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<WorkExperienceType>> => {
      try {
        const res = await axios.get(urls.workExperiences.detail(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  skillEvidences: {
    overview: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<SkillEvidences>> => {
      try {
        const res = await axios.get(
          urls.skillEvidences.overview(urlParams.username)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  block: {
    list: async (): Promise<APIResponse<BlockedUser[]>> => {
      try {
        const res = await axios.get(urls.block.list())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.block.delete(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
