import global from "./resources/global"
import help from "./resources/help"
import notifications from "./resources/notifications"
import profile from "./resources/profile"
import school from "./resources/school"
import users from "./resources/users"

export default {
  school,
  global,
  users,
  notifications,
  profile,
  help,
}
