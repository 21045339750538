export interface CustomWindow extends Window {
  webengage?: {
    user: {
      login: (value: string) => void
      logout: () => void
      setAttribute: (
        key: "we_email" | "we_first_name" | "we_last_name",
        value: string
      ) => void
    }
    // eslint-disable-next-line @typescript-eslint/ban-types
    track: (eventName: string, data?: object) => void
  }
}

declare let window: CustomWindow

// eslint-disable-next-line prefer-destructuring
export const webengage = window.webengage
